import { z } from 'zod';
import { SoharEligibilityStatus } from './sohar-response.dto';
import { type StateAbbrValue } from '@innerwell/us-states';
import { DateTime } from 'luxon';

export type SoharRequestParams = {
   firstName: string;
   lastName: string;
   dateOfBirth: string;
   memberId: string;
   npi: string;
   payerId: string;
   state: StateAbbrValue;
   patientId?: string;

   //optional
   id?: string; // An optional identifier for the verification, maximum length: 45
   placeOfService?: string; // An optional two-digit code that describes the setting in which a service was provided
   subscriber?: {
      firstName: string | null;
      lastName: string | null;
   }; // An optional object to describe the subscriber related to the insurance member ID
   taxonomyCode?: string; // An optional taxonomy code to override the primary taxonomy code associated with the provider's NPI number
};

export const soharVerificationSchema = z.object({
   verificationId: z.string(),
});

export type SoharVerificationQueryDto = z.infer<typeof soharVerificationSchema>;

export const startSoharVerificationSchema = z.object({
   firstName: z.string().min(1, 'First name is required.'),
   lastName: z.string().min(1, 'Last name is required.'),
   dateOfBirth: z
      .string()
      .min(6, 'Date is required.')
      .regex(
         /(0[1-9]|1[012])(\/|-)(0[1-9]|[12][0-9]|3[01])(\/|-)(1|2)\d{3}/,
         'Invalid date.',
      )
      .transform((value) => {
         const cleanValue = value.replaceAll('_', '');
         try {
            return DateTime.fromFormat(cleanValue, 'MM/dd/yyyy', {
               zone: 'utc',
            })
               .toUTC()
               .toFormat('MM/dd/yyyy');
         } catch (_e) {
            return '';
         }
      }), // The patient's birth date in MM/DD/YYYY format

   memberId: z.string().min(6, 'Member ID is required.'), // The insurance member ID
   payerId: z.string().min(4, 'Payer ID is required.'), // The payer ID. See the Sohar payer list

   id: z.string().max(45).optional(), // An optional identifier for the verification, maximum length: 45
   placeOfService: z.string().optional(), // An optional two-digit code that describes the setting in which a service was provided
   subscriber: z
      .object({
         isSameAsPatient: z.coerce.boolean().default(true),
         firstName: z.string(), // The subscriber's first name
         lastName: z.string(), // The subscriber's last name
      })
      .superRefine(({ isSameAsPatient, firstName, lastName }, { addIssue }) => {
         if (isSameAsPatient) {
            return;
         }

         if (!firstName.trim()) {
            addIssue({
               code: z.ZodIssueCode.custom,
               message: 'Primary subscriber first name is required.',
               path: ['firstName'],
            });
         }

         if (!lastName.trim()) {
            addIssue({
               code: z.ZodIssueCode.custom,
               message: 'Primary subscriber first name is required.',
               path: ['lastName'],
            });
         }
      })
      .optional(),
   taxonomyCode: z.string().optional(), // An optional taxonomy code to override the primary taxonomy code associated with the provider's NPI number
});

export type StartSoharVerificationDto = z.infer<
   typeof startSoharVerificationSchema
>;

/**
 * V2 endpoint - Not currently used - maybe in future ?
 */
export type SoharRequestParamsV2 = {
   id?: string; // An optional identifier for the verification, maximum length: 45
   patient: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      state: StateAbbrValue;
      memberId: string;
   };
   payerId: string;
   placeOfServiceCode: string; // An optional two-digit code that describes the setting in which a service was provided //more info: https://www.cms.gov/medicare/coding-billing/place-of-service-codes/code-sets
   specialtyCode: SoharRequestSpecialtyCode;
   subscriber?: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
   }; // An optional object to describe the subscriber related to the insurance member ID

   //taxonomyCode?: string;
   //npi: string;
};
export enum SoharRequestSpecialtyCode {
   AppliedBehavioralAnalysis = 'ABA',
   IntensiveOutpatientProgram = 'IOP',
   OccupationalTherapy = 'OCT',
   PhysicalTherapy = 'PHT',
   PrimaryCare = 'PRC',
   Psychiatry = 'PCH',
   Psychotherapy = 'PSY',
   SpeechLanguagePathology = 'SLP',
   SubstanceUseDisorder = 'SUD',
   TranscranialMagneticStimulation = 'TMS',
   UrgentCare = 'UGC',
   WeightManagement = 'WMT',
}
/****************/

/**************************
 *
 * Sandbox - for testing
 *
 */

export interface TestVerificationData {
   payerId: string;
   memberId: string;
   expectedStatus: SoharEligibilityStatus;
}

export const sandboxTestValues: TestVerificationData[] = [
   {
      payerId: '60054',
      memberId: '00000000',
      expectedStatus: SoharEligibilityStatus.CompleteEligible,
   },
   {
      payerId: '60054',
      memberId: '00000001',
      expectedStatus: SoharEligibilityStatus.CompleteIneligible,
   },
   {
      payerId: '60054',
      memberId: '10000000',
      expectedStatus: SoharEligibilityStatus.ErrorMemberId,
   },
   {
      payerId: '60054',
      memberId: '10000001',
      expectedStatus: SoharEligibilityStatus.ErrorMemberDob,
   },
   {
      payerId: '60054',
      memberId: '10000002',
      expectedStatus: SoharEligibilityStatus.ErrorMemberName,
   },
   {
      payerId: 'OSCAR',
      memberId: '123123',
      expectedStatus: SoharEligibilityStatus.ErrorPayerNpi,
   },
   {
      payerId: '06111',
      memberId: '123123',
      expectedStatus: SoharEligibilityStatus.Pending,
   },
];
