import { z } from 'zod';
import { stateAbbrField, uuidSchema } from '../common';
import { type StateAbbrValue } from '@innerwell/us-states';

export const createRuleSchema = z.object({
   payerId: z.string(),
   state: stateAbbrField,
   planNameExclusions: z.array(z.string()).optional(),
   networkType: z.string().max(2),
   relatedEntityPayerId: z.string(),
   claimsRoutingPayerId: z.string(),
   eligibilityStatus: z.boolean(),
});

export type CreateRulesDto = z.infer<typeof createRuleSchema>;

export const updateRuleSchema = createRuleSchema.extend({
   id: uuidSchema,
});

export type UpdateRulesDto = z.infer<typeof updateRuleSchema>;

export type EligibilityRule = CreateRulesDto & {
   id: string;
   state: StateAbbrValue;

   createdAt: Date;
   updatedAt: Date;
   createdBy: string | null;
   updatedBy: string | null;
};
