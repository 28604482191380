import { z } from 'zod';
import { dateTimeISO8601Field, uuidSchema } from '../common';
import { WelkinPageableResponseWithMetaInfo } from './welkin.types';

export enum WelkinTaskPriority {
   Low = 'LOW',
   Medium = 'MEDIUM',
   High = 'HIGH',
   Urgent = 'URGENT',
}

export enum WelkinTaskStatus {
   Todo = 'TODO',
   InProgress = 'IN_PROGRESS',
   Completed = 'COMPLETED',
   Canceled = 'CANCELED',
}

export interface TaskTemplate {
   name: string;
   taskName: string;
   description: string;
   priority: WelkinTaskPriority;
   timeOfCompletion: number;
   watchersType: string;
}

const welkinTaskPatientSchema = z.object({
   id: uuidSchema,
   name: z.string().nullable(),
});

const welkinTaskAssigneeSchema = z.object({
   id: uuidSchema.optional(),
   name: z.string(),
   enabled: z.boolean(),
});

const welkinTaskWatcherSchema = z.object({
   id: uuidSchema,
   name: z.string(),
   enabled: z.boolean(),
});

const welkinTaskAuthorSchema = z.object({
   id: uuidSchema,
   name: z.string(),
   enabled: z.boolean(),
});

const welkinTaskCommentSchema = z.object({
   id: uuidSchema,
   createdAt: z.string(),
   author: welkinTaskAuthorSchema,
   text: z.string(),
});

export const welkinTaskSchema = z.object({
   name: z.string(),
   description: z.string(),
   dueDate: z.string(),
   status: z.nativeEnum(WelkinTaskStatus),
   priority: z.nativeEnum(WelkinTaskPriority),
   patient: welkinTaskPatientSchema,
   assignee: welkinTaskAssigneeSchema.optional(),
   watchers: z.array(welkinTaskWatcherSchema),
   comments: z.array(welkinTaskCommentSchema),
   templateName: z.string().nullable(),
   watchersType: z.string(),
   createdType: z.string(),
   sourceId: z.string().nullable(),
   sourceType: z.string().nullable(),
   hiddenPhi: z.boolean(),
});

export type WelkinTask = z.infer<typeof welkinTaskSchema> & {
   id: string;
};

export type PaginatedWelkinTasksResponse =
   WelkinPageableResponseWithMetaInfo<WelkinTask>;

export const welkinCommentSchema = z.object({
   author: z.object({
      id: uuidSchema,
   }),
   text: z.string(),
});

export type WelkinComment = z.infer<typeof welkinCommentSchema>;

export const welkinWatcherSchema = z.object({
   id: uuidSchema,
});

export type WelkinWatcher = z.infer<typeof welkinWatcherSchema>;

export const welkinBatchStatus = z.object({
   taskIds: z.array(uuidSchema),
   status: z.nativeEnum(WelkinTaskStatus),
});

export type WelkinBatchStatus = z.infer<typeof welkinBatchStatus>;

export const welkinCreateTaskSchema = z.object({
   name: z.string(),
   description: z.string().optional(),
   priority: z.nativeEnum(WelkinTaskPriority),
   status: z.nativeEnum(WelkinTaskStatus),
   dueDate: z.string().optional(),
   assignee: z.object({ id: uuidSchema }),
   patient: z.object({ id: uuidSchema }).optional(),
   watchers: z.array(z.object({ id: uuidSchema })).optional(),
   comments: z.array(z.object({ text: z.string() })).optional(),
   templateName: z.string(),
});

export type WelkinCreateTask = z.infer<typeof welkinCreateTaskSchema>;

export const welkinUpdateTaskSchema = z.object({
   name: z.string(),
   description: z.string().optional(),
   priority: z.nativeEnum(WelkinTaskPriority),
   status: z.nativeEnum(WelkinTaskStatus),
   dueDate: z.string().optional(),
   assignee: z.object({ id: uuidSchema }).optional(),
   patient: z.object({ id: uuidSchema }).optional(),
});

export type WelkinUpdateTask = z.infer<typeof welkinUpdateTaskSchema>;

export const searchTaskQuery = z.object({
   size: z.number({ coerce: true }).int().min(1).optional(),
   page: z.number({ coerce: true }).int().min(0).optional(),

   assigneeIds: z.array(uuidSchema).optional(), // Clinician ids
   patientIds: z.array(uuidSchema).optional(), //check if array is supported ?
   statuses: z.array(z.nativeEnum(WelkinTaskStatus)).optional(),
   priorities: z.array(z.nativeEnum(WelkinTaskPriority)).optional(),
   templates: z.array(z.string()).optional(),
   dueDate: dateTimeISO8601Field.optional(),
});

export type WelkinSearchTaskQuery = z.infer<typeof searchTaskQuery>;
