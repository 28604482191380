import { z } from 'zod';
import {
   booleanExtendedField,
   dateTimeISO8601Field,
   intExtendedField,
} from '../common';
import {
   SoharRequestParams,
   SoharVerificationIdentifier,
   startSoharVerificationSchema,
} from '../sohar';

export const defaultParamsSchema = z.object({
   dryRun: booleanExtendedField.optional(),
   timeout: intExtendedField.optional(),
});
export type DefaultParamsSchema = z.infer<typeof defaultParamsSchema>;

export const clinicianInputSchema = defaultParamsSchema.extend({
   hours: intExtendedField.optional(), // how much hours will be covered from start date
   start: dateTimeISO8601Field.optional(), // start date time from when to check encounters
});
export type ClinicianInputSchema = z.infer<typeof clinicianInputSchema>;

export const finishedThirdFollowUpSchema = defaultParamsSchema.extend({
   patientId: z.string().uuid().optional(),
});
export type FinishedThirdFollowUpSchema = z.infer<
   typeof finishedThirdFollowUpSchema
>;

export const defaultWithPatientIds = defaultParamsSchema.extend({
   patientIds: z.string().uuid().array(),
});
export type PatientList = z.infer<typeof defaultWithPatientIds>;

export const defaultWithOptionalPatientIds = defaultParamsSchema.extend({
   patientIds: z.string().uuid().array().optional(),
});

export type DefaultWithOptionalPatientIds = z.infer<
   typeof defaultWithOptionalPatientIds
>;

export const defaultWithOptionalPatientId = defaultParamsSchema.extend({
   patientId: z.string().uuid().optional(),
});

export type DefaultWithOptionalPatientId = z.infer<
   typeof defaultWithOptionalPatientId
>;

export const paymentsRetrySchema = defaultParamsSchema.extend({
   // useful for testing
   includeTestPatients: z.boolean().nullish(),
   data: z
      .array(
         z.object({
            transactionId: z.string().optional(),
            paymentFailedCdtId: z.string().uuid().optional(),
         }),
      )
      .optional(),
});
export type PaymentsRetrySchema = z.infer<typeof paymentsRetrySchema>;

export type BatchEligibilityCheckResults = {
   success: SoharVerificationIdentifier[] | undefined;
   errors:
      | {
           request: SoharRequestParams;
           response: SoharVerificationIdentifier;
        }[]
      | undefined;
};

export const soharBatchVerificationFullSchema =
   startSoharVerificationSchema.extend({
      state: z.string().max(2),
      npi: z.string(),
   });
export type SoharBatchVerificationDto = z.infer<
   typeof soharBatchVerificationFullSchema
>;

export const soharBatchVerificationSchema = defaultParamsSchema.extend({
   data: z.array(soharBatchVerificationFullSchema),
   welkinUsers: z.boolean().optional(),
});

const soharBatchVerificationPayloadCheck = z.object({
   verificationId: z.string().uuid(),
   patientId: z.string().uuid().optional(),
   id: z.string().uuid().optional(),
   status: z.number().optional(),
});
export type SoharBatchVerificationPayload = z.infer<
   typeof soharBatchVerificationPayloadCheck
>;

export const soharBatchVerificationCheckSchema = defaultParamsSchema.extend({
   data: z.array(soharBatchVerificationPayloadCheck),
});
export type SoharBatchVerificationCheckDto = z.infer<
   typeof soharBatchVerificationCheckSchema
>;
