import { type ClaimMdGender } from '@innerwell/api-claim-md-types';
import { type ProfilePayer } from '../profiles';

type PayerForInsuranceCdt = Pick<
   ProfilePayer,
   'pdtf-payer_id' | 'pdtf-primary_name'
> & {
   id: string;
};

export enum CdtInsuranceInfoSource {
   Patient = 'patient',
   Cron = 'cron',
   Ops = 'ops',
}

export enum InsuranceInfoStatus {
   NotStarted = 'not_started', //not part of welkin CDT, only frontend status

   Pending = 'pending',
   Eligible = 'eligible',
   Ineligible = 'ineligible',
   Error = 'error',
   PendingError = 'pending_error',
}

export enum InsuranceError {
   None = 'none',
   MemberId = 'member_id',
   MemberDob = 'member_dob',
   MemberName = 'member_name',
   Payer = 'payer',
   PayerNpi = 'payer_npi',
   NetworkStatusPending = 'network_status_pending',
   DiscrepancyClinician = 'discrepancy_clinician_id',
   DiscrepancyPayer = 'discrepancy_payer_id',
}

export type CdtInsuranceInfo = {
   'cdtf-plan-name'?: string | null;
   'cdtf-payer-id'?: string | null;
   'cdtf-payer-name'?: string | null;
   // These values might change.
   'cdtf-estimated-co-pay'?: number | null;
   'cdtf-estimated-co-insurance'?: number | null;
   'cdtf-database-entity-id'?: string;
   'cdtf-insurance-expiration-date'?: string | null;
   'cdtf-co-pay'?: number | null;
   'cdtf-plan-type'?: 'clinician' | 'psychotherapist' | null;
   'cdtf-member-id'?: string | null;
   'cdtf-primary-subscriber'?: boolean | null;
   'cdtf-primary-subscriber-first-name'?: string | null;
   'cdtf-primary-subscriber-last-name'?: string | null;
   'cdtf-primary-subscriber-gender'?: ClaimMdGender | null;

   // Payer id for card
   'cdtf-payer-id-card'?: PayerForInsuranceCdt | null;
   // Payer id for claims
   'cdtf-payer-id-claims'?: PayerForInsuranceCdt | null;

   'cdtf-source': CdtInsuranceInfoSource;

   'cdtf-insurance-start-date': string;
   'cdtf-insurance-end-date'?: string | null;

   //'cdtf-needs-manual-check'?: boolean; //removed - superseded by status
   //'cdtf-manually-recheck-eligibility'?: boolean | null; //removed - superseded by status
   'cdtf-is-valid'?: boolean; //removed - superseded by status

   'cdtf-insurance-status': InsuranceInfoStatus;
   'cdtf-error'?: InsuranceError;
};
